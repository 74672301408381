import React from 'react'
import { Button, Card } from 'react-bootstrap'
import YellowCircle from '../../assets/svg/yellow-circle.svg'
import WashincMachine from '../../assets/img/washing-machine.png'
import VariousImg1 from '../../assets/img/various-img-1.png'
import { ReactSVG } from 'react-svg'
import CallICon from '../../assets/svg/call-icon.svg'
import CollectIcon from '../../assets/svg/collect-icon.svg'
import WashIcon from '../../assets/svg/washing-icon.svg'
import DeliverICon from '../../assets/svg/delivery-icon.svg'
import Checklist from '../../assets/svg/checklist.svg'
import CardIcon1 from '../../assets/svg/card-icon1.svg'
import CardIcon2 from '../../assets/svg/card-icon2.svg'
import CardIcon3 from '../../assets/svg/card-icon3.svg'
import CardIcon4 from '../../assets/svg/card-icon4.svg'
import CardIcon5 from '../../assets/svg/card-icon5.svg'
import CardIcon6 from '../../assets/svg/card-icon6.svg'
import BigCardIcon from '../../assets/img/Thinking face-bro 2.png'


const HomepageComponent = () => {
  return (
    <div className='laundry-box'>
    <section className='lb-homepage'>
      <div className='homepage-banner row'>
        <div className='col-lg-6 col-md-6 col-sm-6'>
          <div className='summary-text'>
            <div className='big-text'>
              <h1>Provides </h1>
              <h1>High Quality</h1> 
              <h1>Laundry Services</h1>
            </div>
            <div className='lil-text'>
              <p>Will be the leading provider cleaning service by</p>
                <p>creating positive impact on all the quality</p>
            </div>
            <Button className='lb-btn-primary font-18 fw-600' style={{width: '30%'}}>Get Started</Button>
          </div>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-6 right-content-wrapper'>
          <div className='right-showcase'>
            <div className='image-sc'>
              <img className='machine-img'  src={WashincMachine} alt='yellow-circle' />
              <img className='circle-img'  src={YellowCircle} alt='yellow-circle' />
            </div>
          </div>
        </div>
      </div>

      <div className='main-content'>
        <div className='row value-section'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className='mc-left-showcase'>
              <div className='img-wrapper'>
                <img src={VariousImg1} alt='img1' className='wash-img'/>
                <Card className='text-support-card'>
                  <Card.Body className='card-body'>
                    <div className='card-text'>
                      <h4>Clean clothes,</h4>
                      <h4>happy you</h4>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className='mc-right-showcase'>
              <div className='big-text'>
                <h4>From stains to freshness,</h4>
                <h4>we've got your laundry</h4>
                <h4>needs covered</h4>
              </div>
              <div className='lil-text row'>
                <div className='col-6'>
                  <h5>70+</h5>
                  <p>Increase customer convenience</p> 
                  <p>because they no longer need to</p> 
                  <p>bother bringing clothes to the</p> 
                  <p>laundry directly.</p>
                </div>
                <div className='col-6'>
                  <h5>156+</h5>
                  <p>With an online ordering and</p> 
                  <p>payment system, business owners</p> 
                  <p>can reduce the time needed to</p> 
                  <p>serve customers.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row value-section mobile-view'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className='mc-left-showcase'>
              <div className='title-instruction'>
                <h5>How it works</h5>
                <div className='big-text'>
                  <h4>Simple step to</h4>
                  <h4>make online booking</h4>
                </div>
                <div className='lil-text'>
                  <p>Will be the leading provider cleaning service by </p>
                  <p>creating positive impact on all the quality</p>
                </div>
                <Button className='lb-btn-primary font-18 fw-600' style={{width: '30%'}}>Get it now</Button>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className='mc-right-showcase'>
              <div class="row row-cols-2 instruction-show">
                <div class="col pb-5">
                  <div className='row'>
                    <h4 className='col-2'>01</h4>
                    <div className='col'>
                      <ReactSVG src={CallICon} />
                        <div className='pt-4'>
                          <p>Call us and</p> 
                          <p>book order</p>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="col pb-5">
                  <div className='row'>
                    <h4 className='col-2'>02</h4>
                    <div className='col'>
                      <ReactSVG src={CollectIcon} />
                      <div className='pt-4'>
                          <p>We collect</p> 
                          <p>your clothes</p>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="col pb-5">
                  <div className='row'>
                    <h4 className='col-2'>03</h4>
                    <div className='col'>
                      <ReactSVG src={WashIcon} />
                      <div className='pt-4'>
                          <p>We clean</p> 
                          <p>your clothes</p>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="col pb-5">
                  <div className='row'>
                    <h4 className='col-2'>04</h4>
                    <div className='col'>
                      <ReactSVG src={DeliverICon} />
                      <div className='pt-4'>
                          <p>We Deliver</p> 
                          <p>to your home</p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='value-section'>
          <div className='row benefit-text'>
            <div className='col-6'>
              <div className='big-text-wrapper'>
                <h4>The Best Guarantee In</h4>
                <h4>The Business.</h4>
              </div>
            </div>
            <div className='col-6 d-flex justify-content-end'>
              <div className='lil-text-wrapper'>
                <p>Will be the leading provider cleaning service by</p>
                <p>creating positive impact on all the quality</p>
              </div>
            </div>
          </div>
          <div className='row mobile-card-container'>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <Card className='lb-card mobile-card-view'>
                <Card.Body className='d-flex gap-3'>
                  <ReactSVG className='lb-card-icon' src={Checklist}  />
                  <div className='card-text-wrapper'>
                    <p>100% Satisfaction</p>
                    <p>Guarantee</p>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <Card className='lb-card mobile-card-view'>
                <Card.Body className='d-flex gap-3'>               
                  <ReactSVG className='lb-card-icon' src={Checklist}  />
                  <div className='card-text-wrapper'>
                    <p>Fast & High</p>
                    <p>Quality</p>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <Card className='lb-card mobile-card-view'>
                <Card.Body className='d-flex gap-3'>
                  <ReactSVG className='lb-card-icon' src={Checklist}  />
                  <div className='card-text-wrapper'>
                    <p>100% Cashback</p>
                    <p>Guaranteed</p>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>

        <div className='value-section'>
          <div className='services-title'>
            <div className='title-wrapper'>
              <h4>The services we provide</h4>
              <h4>are for your convenience</h4>
            </div>
            <div className='tag-wrapper'>
              <p>By relying on laundry services, customers can ensure their</p>
              <p>clothes are always clean, tidy and ready to wear.</p>
            </div>
          </div>
          <div className='row services-card'>
            <div className='col-md-4 col-lg-4 col-sm-12 pb-3'>
              <Card className='lb-card-primary'>
                <Card.Body>
                    <div className='icon-wrapper'>
                      <ReactSVG src={CardIcon1} />
                    </div>
                    <div className='title-wrapper'>
                      <h5>Wash and fold</h5>
                    </div>
                    <div className='text-body-wrapper'>
                      <p>This service includes the washing,</p>
                      <p>drying and folding of ordinary </p>
                      <p>items.</p>
                    </div>
                </Card.Body>
              </Card>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12 pb-3'>
            <Card className='lb-card-primary'>
                <Card.Body>
                    <div className='icon-wrapper'>
                      <ReactSVG src={CardIcon2} />
                    </div>
                    <div className='title-wrapper'>
                      <h5>Dry cleaning</h5>
                    </div>
                    <div className='text-body-wrapper'>
                      <p>Dry cleaning services are</p>
                      <p>intended for cleaning clothes. </p>
                    </div>
                </Card.Body>
              </Card>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12 pb-3'>
            <Card className='lb-card-primary'>
                <Card.Body>
                    <div className='icon-wrapper'>
                      <ReactSVG src={CardIcon3} />
                    </div>
                    <div className='title-wrapper'>
                      <h5>Ironing</h5>
                    </div>
                    <div className='text-body-wrapper'>
                      <p>Ironing service is intended for </p>
                      <p>ironing clothes so they look neat. </p>
                    </div>
                </Card.Body>
              </Card>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12 pb-3'>
            <Card className='lb-card-primary'>
                <Card.Body>
                    <div className='icon-wrapper'>
                      <ReactSVG src={CardIcon4} />
                    </div>
                    <div className='title-wrapper'>
                      <h5>Express service</h5>
                    </div>
                    <div className='text-body-wrapper'>
                      <p>Fast laundry service for urgent </p>
                      <p>clothes cleaning and delivery.</p>
                    </div>
                </Card.Body>
              </Card>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12 pb-3'>
            <Card className='lb-card-primary'>
                <Card.Body>
                    <div className='icon-wrapper'>
                      <ReactSVG src={CardIcon5} />
                    </div>
                    <div className='title-wrapper'>
                      <h5>Carpet cleaning</h5>
                    </div>
                    <div className='text-body-wrapper'>
                      <p>Laundry workers can also provide</p>
                      <p>carpet and rug cleaning services.</p>
                    </div>
                </Card.Body>
              </Card>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12 pb-3'>
            <Card className='lb-card-primary'>
                <Card.Body>
                    <div className='icon-wrapper'>
                      <ReactSVG src={CardIcon6} />
                    </div>
                    <div className='title-wrapper'>
                      <h5>Duvet and sheet cleaning</h5>
                    </div>
                    <div className='text-body-wrapper'>
                      <p>Laundry service for bulky items</p>
                      <p>like blankets and pillows.</p>
                    </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>

        <div className='value-section'>
          <div className='promo-card'>
            <Card className='big-card-promo'>
              <Card.Body className='big-card-body'>
                <div className='image-wrapper'>
                  <img src={BigCardIcon} className='image-content' alt='promo-img' width={432} />
                </div>
                <div className='text-wrapper'>
                  <h4>Get promo for new</h4>
                  <h4 className='mb-5'>customer, don't miss it!</h4>
                  <Button className='lb-btn-primary font-18 fw-600'>Get it now</Button>
                </div>
                <div className='mobile-text-wrapper'>
                  <h4>Get promo for new customer don't miss it!</h4>
                </div>
                <Button className='mobile-text-wrapper lb-btn-primary font-18 fw-600'>Get it now</Button>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default HomepageComponent