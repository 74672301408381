import React from 'react'
import Headbar from '../../components/Layout/Headbar'
import Footer from '../../components/Layout/Footer'
import ContactUsComponent from '../../components/ContactUs'

const ContactUs = () => {
  return (
    <>
    {/* < className='container'> */}
    <Headbar />
    <ContactUsComponent />
    <Footer />
      
    {/* </> */}
    </>
  )
}

export default ContactUs