import React from 'react'
import LaundryboxLogo from '../../assets/svg/laundrybox_logo.svg'

const Footer = () => {
  return (
    <div className='laundry-box'>
      <div className='lb-footer'>
        <div className='row footer-content'>
          <div className='col-md-6 col-lg-6 col-sm-12 py-2'>
            <div className='footer-title'>
              <img  src={LaundryboxLogo} width={400} alt='logo' />
            </div>
            <ul className='direction-link'>
              <li>Home</li>
              <li>Services</li>
              <li>About</li>
              <li>Faq</li>
            </ul>
          </div>
          <div className='col-md-6 col-lg-6 col-sm-12'>
            <div className='row helper-content'>
              <div className='col-6'>
                <ul>
                  <li className='helper-title'><p>Services</p></li>
                  <li>Dry cleaning</li>
                  <li>Laundry services</li>
                  <li>Leather and suade</li>
                </ul>
              </div>
              <div className='col-6'>
              <ul>
                  <li className='helper-title'><p>Support</p></li>
                  <li>Customer services</li>
                  <li>Email us</li>
                  <li>Privacy policy</li>
                  <li>Terms and conditions</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='copyright-footer'>
              <p>2023 Allright reserved</p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Footer