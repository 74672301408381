import React from 'react'
import { Card,  FloatingLabel, Form, Button } from 'react-bootstrap'
import DialIcon from '../../assets/svg/dial-icon.svg'
import FaxIcon from '../../assets/svg/fax-icon.svg'
import EmailIcon from '../../assets/svg/email-icon.svg'
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import { ReactSVG } from 'react-svg';

const ContactUsComponent = () => {
  return (
    <div className='laundry-box'>
      <div className='lb-contact'>
        <div className='card lb-card-form'>
          <div className='lb-form-sign'>
           <h3>Get in <span>Touch</span></h3>
          <p>Enim tempor eget pharetra facilisis sed maecenas adipiscing.</p>
          <p> Eu leo molestie vel, ornare non id blandit netus</p>
        </div>
        <div className='lb-form-logreg pb-5'>
          <div className='row'>
            <div className='col-12'>
              <FloatingLabel
                controlId="floatingInput"
                label="Name *"
                className="mb-3 lb-form-label"
              >
                <Form.Control type="text" style={{width:'100%'}} placeholder="name@example.com" />
              </FloatingLabel>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <FloatingLabel
                controlId="floatingInput"
                label="Phone number"
                className="mb-3 lb-form-label"
              >
                <Form.Control className='lb-form-control' type="text" placeholder="Password" />
              </FloatingLabel>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className="mb-3 lb-form-label"
              >
                <Form.Control type="email" style={{width:'100%'}} placeholder="name@example.com" />
              </FloatingLabel>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <FloatingLabel
                controlId="floatingInput"
                label="How did you find us?"
                className="mb-3 lb-form-label"
              >
                <Form.Control type="text" style={{width:'100%'}} placeholder="name@example.com" />
              </FloatingLabel>
            </div>
          </div>
        </div>
        <Button className='lb-btn-primary font-18 fw-700' size="lg">
          SEND
        </Button>
        <div className='contact-container' style={{paddingTop: '4rem'}}>
          <div className='contact-index-wrapper'>
            <i><ReactSVG src={DialIcon} /></i>
            <div className='index-text'>
              <p className='m-0'>Phone</p>
              <span className='m-0'>03 5432 1234</span>
            </div>
          </div>
          <div className='contact-index-wrapper'>
            <i><ReactSVG src={FaxIcon} /></i>
            <div className='index-text'>
              <p className='m-0'>Fax</p>
              <span className='m-0'>03 5432 1234</span>
            </div>
          </div>
          <div className='contact-index-wrapper'>
              <i><ReactSVG src={EmailIcon} /></i>
            <div className='index-text'>
              <p className='m-0'>Email</p>
              <span className='m-0'>info@marcc.com.au</span>
            </div>
          </div>
          {/* <p className='fw-400 font-14'>Sudah punya akun? <span className='superlink'>Masuk</span></p> */}
        </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUsComponent