import Homepage from './pages/Homepage';
import { Route, Routes } from "react-router-dom";
import ContactUs from './pages/ContactUs'

function App() {
  return (
      <Routes>
        <Route path="/" element={<Homepage/>} />
        <Route path="/contact-us" element ={<ContactUs />} />
      </Routes>
  );
}

export default App;
