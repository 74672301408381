/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import LaundryboxLogo from '../../assets/svg/laundrybox_logo.svg'
import { Button } from 'react-bootstrap'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

const Headbar = () => {
  const [isOpenMenu, setIsOpenMenu] = React.useState(false)
  const navigate = useNavigate()

  return (
    <div className='laundry-box'>
      <nav className='lb-headbar  m-0'>
        <div className='lb-logo-wrapper '>
          <img src={LaundryboxLogo} className=''  alt='laundry-box-logo' width={200}/>
          <div id='hb-mobile' onClick={() => setIsOpenMenu(!isOpenMenu)}>
          <i id='bar'>
            { isOpenMenu ? <CloseIcon fontSize='large' /> : <MenuIcon fontSize='large' /> }
          </i>
        </div>  
        </div>
        <div className='headbar-direction '>
          <ul className={ isOpenMenu ? 'active' : '' }>
            <li>
              <Link to='/'>Home</Link>              
            </li>
            <li>
              <a href='#'>Service</a>              
            </li>
            <li>
              <a href='#'>About</a>              
            </li>
            <li>
              <a href='#'>FaQ</a>              
            </li>
            <li>
              <Button
              onClick={() => navigate('/contact-us')} 
              className='lb-btn-primary hb-btn font-18 fw-600'>
                Contact Us
              </Button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default Headbar