import React from 'react'
import HomepageComponent from '../../components/Homepage'
import Headbar from '../../components/Layout/Headbar'
import Footer from '../../components/Layout/Footer'

const Homepage = () => {
  return (
    <>
      {/* < className='container'> */}
      <Headbar />
      <HomepageComponent />
      <Footer />
        
      {/* </> */}
      </>
  )
}

export default Homepage